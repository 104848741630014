import React, { useState } from 'react';

import { Layout } from '../../components/layouts';
import { SEO } from '../../components/common';
import {
  OriginalMattressProductSection,
  OriginalMattressAccordionSection,
  OriginalMattressBenefitSection,
  MattressLateralSection,
  OriginalMattressLayerSection,
  OriginalMattressIntroSection,
  OriginalMattressFirmnessSection,
  OriginalMattressUpsellHeroSection,
  OriginalMattressReviewHeroSection,
  OriginalMattressUpsellGridSection,
  WoosaPromiseIconGridSection,
  OriginalMattressProductFixedBottomBar,
} from '../../components/sections';

const WoosaOriginalMattressPage: React.FC = () => {
  const [isPlusCover, setIsPlusCover] = useState(false);

  const handleIsPlusCoverChange = (isChecked: boolean) => {
    setIsPlusCover(isChecked);
  };
  return (
    <Layout>
      <SEO
        title="The Original Mattress, Premium Latex Memory Foam Mattress Singapore"
        description="Shop Premium Latex and Memory Foam mattresses from Woosa. Comfortable and supportive, made and tested for Singaporeans."
      />
      <OriginalMattressProductSection
        isPlusCover={isPlusCover}
        onChangeIsPlusCover={handleIsPlusCoverChange}
      />
      <OriginalMattressIntroSection />
      <OriginalMattressFirmnessSection />
      <OriginalMattressLayerSection isPlusCover={isPlusCover} />
      <OriginalMattressBenefitSection />
      <OriginalMattressUpsellHeroSection />
      <OriginalMattressAccordionSection />
      <OriginalMattressReviewHeroSection />
      <OriginalMattressUpsellGridSection />
      <WoosaPromiseIconGridSection version="v2" />
      <MattressLateralSection version="v2" />
      <OriginalMattressProductFixedBottomBar
        isPlusCover={isPlusCover}
        onChangeIsPlusCover={handleIsPlusCoverChange}
      />
    </Layout>
  );
};
export default WoosaOriginalMattressPage;
